import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Layout } from '../layouts/Layout';
import { SEO } from '../components/SEO';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Getting started" mdxType="SEO" />
    <h1>{`Getting started`}</h1>
    <p>{`This guide will walk you through setting up the project both for development and production-ready use.`}</p>
    <p>{`If you are looking for instructions on how to use a specific component from the Ulule Design System library, please refer to `}<a parentName="p" {...{
        "href": "/components"
      }}>{`the demo page of that component`}</a>{`.`}</p>
    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nodejs.org"
        }}>{`Node.js 12`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://yarnpkg.com"
        }}>{`Yarn`}</a></li>
      <li parentName="ul">{`Cloning the `}<inlineCode parentName="li">{`ulule/ulule`}</inlineCode>{` repository`}</li>
    </ul>
    <p>{`Please have these ready before going further through this guide.`}</p>
    <p>{`ℹ️`}{` `}{` `}{`It is recommended to use `}<a parentName="p" {...{
        "href": "https://github.com/nvm-sh/nvm"
      }}><inlineCode parentName="a">{`nvm`}</inlineCode></a>{` to easily change `}<inlineCode parentName="p">{`node`}</inlineCode>{` versions.`}</p>
    <h2>{`Preamble`}</h2>
    <p>{`Source files for both this website and the Ulule Design System library are part of the `}<inlineCode parentName="p">{`ulule/ulule`}</inlineCode>{` repository.`}</p>
    <p>{`Ulule is built as a monorepo, but the front-end applications are organized as `}<a parentName="p" {...{
        "href": "https://classic.yarnpkg.com/en/docs/workspaces/"
      }}>{`Yarn workspaces`}</a>{`, for easier access. Thus, you can choose between navigating to each app folder to run commands, or using `}<inlineCode parentName="p">{`yarn workspace`}</inlineCode>{` from the `}<inlineCode parentName="p">{`<ULULE_REPO>/frontend`}</inlineCode>{` directory.`}</p>
    <p>{`ℹ️`}{` `}{`From now on, this guide `}<strong parentName="p">{`WILL ASSUME`}</strong>{` that you are placed in the `}<inlineCode parentName="p">{`<ULULE_REPO>/frontend`}</inlineCode>{` directory and making use of Yarn workspaces.`}</p>
    <h2>{`Dependencies`}</h2>
    <p>{`⚠️`}{` `}<strong parentName="p">{`Make sure you are running `}<inlineCode parentName="strong">{`node`}</inlineCode>{` 12`}</strong>{` before installing dependencies.`}</p>
    <p>{`Installing the library dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn workspace @ulule/owl-kit-components install
`}</code></pre>
    <p>{`Installing the website dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn workspace @ulule/owl-kit-website install
`}</code></pre>
    <h2>{`Development`}</h2>
    <h3>{`Watch mode`}</h3>
    <p>{`Run watch mode for the library:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn workspace @ulule/owl-kit-components watch
`}</code></pre>
    <p>{`Run watch mode for the website:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn workspace @ulule/owl-kit-website develop
`}</code></pre>
    <p>{`Visit `}<a parentName="p" {...{
        "href": "http://localhost:8000/"
      }}>{`http://localhost:8000/`}</a>{` to view the local instance of the website.`}</p>
    <p>{`ℹ️`}{` `}<strong parentName="p">{`N.B.`}</strong>{`:`}</p>
    <p>{`While in watch mode, each time you update the code of the library or the website, your website local instance will automatically reload. Sometimes it takes a long time, be patient 😄.`}</p>
    <p>{`If you create a component or change the props of an existing one, you might have to re-run `}<inlineCode parentName="p">{`develop`}</inlineCode>{` to see the changes.`}</p>
    <h2>{`Production build`}</h2>
    <p>{`The following commands will allow you to build production-ready bundles.`}</p>
    <p>{`To build the library:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn workspace @ulule/owl-kit-components build
`}</code></pre>
    <p>{`To build the website:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn workspace @ulule/owl-kit-website build
`}</code></pre>
    <h2>{`Analyze the bundle`}</h2>
    <p>{`If you need to analyze the library bundle to understand some issues, follow these steps:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn workspace @ulule/owl-kit-components build
yarn workspace @ulule/owl-kit-components analyze
`}</code></pre>
    <p>{`It uses `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/source-map-explorer"
      }}>{`source-map-explorer`}</a>{` bundle.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      